(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("simpleXmppService", simpleXmppService);

	function simpleXmppService($rootScope,
		$filter,
		$timeout,
		$log,
		$sanitize,
		$localStorage,
		$translate,
		coreData,
		coreDataContacts,
		coreDataSettings,
		browserNotifications,
		preferencesStorage,
		tokenRefreshService,
		userDataService,
		claimsService,
		authStorage,
		popupService,
		chatVideoService,
		signalrHubManager,
		stropheConnectionService,
		errorHandling) {

		var idCounter = 1;
		var uid = moment().valueOf();
		var unavailableTimeouts = {};

		// TODO: Get pics from api/v1/contacts/domain

		// Data
		var vm = this;
		vm.boshUrl = null;
		vm.needsReconnect = false;
		vm.host = null;
		vm.username = null;
		vm.email = null;
		vm.states = stropheConnectionService.parameters.states;
		//vm.status = "connecting";
		$rootScope.$on('signalRHubManagerReconnected', reconnect);
		$rootScope.$on('xmpp.reconnect-needed', reconnect);
		function reconnect() {
			if (vm.needsReconnect)
				vm.close();
			stropheConnectionService.connect(authStorage.getToken())
				.then(onConnect, errorHandling.report);
		}
		vm.close = function () {
			if (stropheConnectionService.parameters.connected) {
				stropheConnectionService.disconnect();
			}
		}
		vm.contactCategories = [];
		vm.logTraffic = false;
		vm.parameters = {
			get connected() {
				return stropheConnectionService.parameters.connected;
			},
			get status() {
				const stat = stropheConnectionService.parameters.status;
				return stat;

			},

			get prevStatus() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "prevStatus");
				if (value === undefined) {
					value = "available"; preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value);
				}
				return value;
			},
			set prevStatus(value) { preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value); },

			get unreadCounts() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "unreadCounts");
				if (value === undefined) {
					value = {}; preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value);
				}
				return value;
			},
			set unreadCounts(value) { preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value); },

		};

		// Functions
		vm.markRead = markRead;
		vm.setStatus = setStatus;
		vm.findContact = findContact;
		vm.popout = popout;
		vm.init = init;
		vm.getUserStatus = getUserStatus;
		vm.getMenuItems = getMenuItems;
		// Startup
		if (window.location.href.indexOf('popout') > 0 && window.location.href.indexOf("popout/email") === -1)
			return;

		if (!window.Strophe)
			throw new Error('Please make sure to include Strophe library. http://strophe.im/strophejs/');

		function init() {
			if (claimsService.isSysAdmin())
				return;

			$rootScope.$on('signalR.chatVideo.receivingCall', onReceivingCall);
			$rootScope.$on("signalR.chat.markRead", function (event, contactJid) {
				recalculateUnread(contactJid);
			});
			userDataService
				.init()
				.then(function () {
					vm.isVisible = userDataService.user.settings.features && userDataService.user.settings.features.enableChat && !claimsService.impersonating();
					if (vm.isVisible) {
						// Check if chat popup is open
						vm.querying = true;
						preferencesStorage.setSortingFilteringParam("chat", "queryPopup", true);
						vm.querying = false;
						vm.parameters.unreadCounts = {};

						stropheConnectionService.connect(authStorage.getToken())
							.then(function (success) {
								onConnect();
								cleanUnreadCounts();
								vm.needsReconnect = true;
								//if (success.status !== "offline") {

								//} else {
								//	$rootScope.$broadcast('xmpp.property-changed', { status: success.status });
								//}

							}, errorHandling.report);
					}
				}, function () { });


		}


		function cleanUnreadCounts() {
			var counts = vm.parameters.unreadCounts;
			vm.parameters.unreadCounts =
				Object.keys(counts).reduce((acc, key) => {
					if (counts[key].unread > 0) {
						acc[key] = counts[key];
					}
					return acc;
				}, {});
		}

		function markRead(contact) {
			contact.unreadCount = 0;
			recalculateUnread();
		}

		function setStatus(newStatus) {
			stropheConnectionService.parameters.status = newStatus;
			$rootScope.$broadcast('xmpp.property-changed', { status: newStatus });
		}


		function popout(selectContact) {
			if (selectContact === "recent")
				preferencesStorage.setSortingFilteringParam("chat", "selectRecentUnread", true);
			else
				preferencesStorage.setSortingFilteringParam("chat", "selectContact", selectContact.jid);

			vm.querying = true;
			$timeout(function () { vm.querying = false; }, 5000);
			$rootScope.$applyAsync();
			const chatWindow = window.open((stSiteRoot || '/') + 'interface/root#/popout/chat', "chat", "resizable=1, " + popupService.dimensions.chat);
			chatWindow.focus();


		}

		// Private Events

		function onConnect() {
			stropheConnectionService.removeHandlers();
			const xmppRequest = $iq({ type: "get", id: "_roster_" + (idCounter++) }).c("query", { xmlns: Strophe.NS.ROSTER });
			stropheConnectionService.parameters.connection.sendIQ(xmppRequest, onRoster);
			stropheConnectionService.parameters.connection.messageCarbons.enable(onMessageCarbon);
			//stropheConnectionService.parameters.connection.send($pres({ to: userDataService.user.emailAddress.toLowerCase(), "type": "subscribe" }));
		}

		function onMessageCarbon(carbon) {
			var body = $(carbon.innerMessage).children("body").text() || "";
			if (!body)
				return;

			if (carbon.direction !== 'sent') {
				onMessage(carbon.innerMessage);
			}
		}

		function onMessage(msg) {
			try {
				var from = $(msg).attr("from");
				var sender = from.split("/")[0];
				var body = $(msg).children("body").text() || "";
				var state = $(msg).find("active,composing,paused,gone")[0];
				var bareJid = Strophe.getBareJidFromJid(from);
				var msgType = $(msg).attr('type');
				var foundItem = findContact(bareJid);
				if (foundItem) {
					var name = foundItem.name || bareJid;
					if (bareJid === coreData.user.emailAddress) {
						name = coreData.user.displayName ? coreData.user.displayName : name;
					}

					if (msgType == 'chat' && body) {
						var html_body = $('html > body', msg);
						if (html_body.length > 0) {
							html_body = $('<div>').append(html_body.contents()).html();
						} else {
							html_body = null;
						}

						var isplain = !html_body;
						if (!html_body)
							html_body = body;

						foundItem.unreadCount++;
						foundItem.dt = new Date();
						recalculateUnread();

						// TODO: Check if this is already a visible page or not
						if (coreDataSettings.userSettings.notifyOnChatMessages) {
							var convertedHtml = $("<div>" + html_body + "</div>").text();
							if (convertedHtml.toLowerCase().indexOf('http://') == 0 ||
								convertedHtml.toLowerCase().indexOf('https://') == 0)
								convertedHtml = $filter('translate')('LINK_RECEIVED');

							browserNotifications.show(name,
								{
									body: convertedHtml,
									tag: bareJid,
									icon: (stSiteRoot || '/') + 'interface/img/notifications/chat.png',
									notifyClick: function (e) {
										popout(foundItem);
									}
								});
						}

					}
				}
			} catch (ex) { $log.error(ex); }
			return true;
		}

		function onGroupMessage(iq) {
			return true;
		}
		function getUserStatus(email) {
			const contact = findContact(email);
			return contact
				? contact.status
				: null;
		}
		function getMenuItems() {

			const unreadContacts = vm.contactCategories.reduce((acc, category) => {
				return category.contacts.reduce((categoryTotal, contact) => {
					if (contact.unreadCount > 0) {
						acc.push({
							rid: contact.jid,
							label: contact.name,
							link: contact.jid,
							icon: contact.status === "room" ? "toolsicon-group" : "toolsicon-person",
							unread: contact.unreadCount,
							status: contact.status === "room" ? "" : contact.status,
							pic: contact.pic,
							ts: contact.dt
						});
					}
					return acc;
				}, acc);
			}, []);
			return unreadContacts;
		}
		async function onRoster(roster) {
			//I'm using a try catch here because i've seen errors happen here and chrome doesn't catch and throw them itself.
			try {
				await coreDataContacts.ensureContactsLoadedPromise();

				stropheConnectionService.parameters.connection.addHandler(onPresence, null, 'presence');
				vm.contactCategories = [];
				for (let item of $(roster).find("item")) {
					let contact = await contactFromIqItem(item);
					addRosterContact(contact);
					addContact(contact.jid, contact.name);
				}
				try {
					const promises = [queryUnseenMessagesAsync(2)];
					//await queryUnseenMessagesAsync(2);
					const chatGroups = vm.contactCategories.find(cat => cat.name.endsWith("Aliases"));
					if (chatGroups && chatGroups.contacts) {
						for (const groupContact of chatGroups.contacts) {
							promises.push(queryUnseenGroup(groupContact, 2));
						}

					}
					await Promise.all(promises);
					recalculateUnread();
				} catch (e) {
					console.error("failed to get message history: ", e);
				}


				stropheConnectionService.parameters.connection.addHandler(onRosterChanged, Strophe.NS.ROSTER, 'iq', 'set');

				stropheConnectionService.parameters.connection.addHandler(onIQ, null, 'iq');
				stropheConnectionService.parameters.connection.addHandler(onMessage, null, 'message', "chat");
				stropheConnectionService.parameters.connection.addHandler(onGroupMessage, null, 'message', "groupchat");
				vm.setStatus(vm.parameters.status);
				$rootScope.$broadcast('chat.user-status-changed', { all: true });
			} catch (exception) {
				$log.error(exception); //Chrome doesn't seem to like the error I get here so the throw statement doesn't work on its on.
				throw new Error(exception);
			}
		}
		function onPresence(presence) {

			try {
				setPresence(presence);
			} catch (e) {
				console.error("Error in setPresence:", presence, e);
			}
			return true;
		}
		function getStatusFromShow(show, ptype) {
			if (ptype === 'error') {
				return 'error';
			}

			var status;
			if (ptype === 'unavailable') {
				status = 'offline';
			} else {
				if (show === "" || show === "chat") {
					status = 'available';
				} else if (show === 'dnd') {
					status = 'dnd';
				} else {
					status = 'away';
				}
			}

			return status;
		}
		function setPresence(presence) {
			const ptype = $(presence).attr('type');
			const from = $(presence).attr('from');
			const bareJid = Strophe.getBareJidFromJid(from);
			const resourceID = Strophe.getResourceFromJid(from);
			const show = $(presence).find("show").text();
			if (ptype === 'error')
				return;
			const status = getStatusFromShow(show, ptype);
			if (!status) return;
			if (bareJid.toLowerCase() === userDataService.user.emailAddress.toLowerCase()) {
				if (resourceID) {

					preferencesStorage.setSortingFilteringParam("chat", "status", status);
					$rootScope.$broadcast('xmpp.property-changed', { status: status });
				}
				return;
			}

			if (vm.roomJoining === from && vm.onRoomJoined) {
				if (ptype === 'error')
					vm.onRoomJoined(false, from);
				var isRoomJoinRequestResponse = $(presence).find("status[code='110']").length > 0;
				if (isRoomJoinRequestResponse)
					vm.onRoomJoined(true, from);
			}


			var foundItem = findContact(bareJid);
			if (foundItem && foundItem.status !== "room" && foundItem.status !== status) {
				foundItem.status = status;

				$rootScope.$broadcast('xmpp.contacts-changed', { contactCategories: vm.contactCategories, loaded: true });
			}
		}
		async function onRosterChanged(roster, iq) {
			await coreDataContacts.ensureContactsLoadedPromise();

			for (let item of $(roster).find("item")) {
				var contact = await contactFromIqItem(item);
				if (contact.subscription == "remove")
					removeRosterContact(contact);
				else
					addRosterContact(contact);
			}
			recalculateUnread();
			return true;
		}

		function onIQ(iq) {
			return true;
		}

		function onReceivingCall(ev, data) {
			if (vm.parameters.isPopup) return;

			var contact = findContact(data.sourceEmail);

			var name = contact.name || data.sourceEmail;
			browserNotifications.show(name,
				{
					body: $translate.instant("CHAT_CALL_FROM", { name: name }),
					tag: data.sourceEmail,
					icon: (stSiteRoot || '/') + 'interface/img/notifications/chat.png',
					notifyClick: function (e) {
						preferencesStorage.setSortingFilteringParam("chat", "receivingCall", data);
						popout(contact);
					}
				});
		}
		async function queryUnseenGroup(contact, months) {
			const today = new Date();
			const oneYearAgo = new Date();
			oneYearAgo.setMonth(today.getMonth() - months);
			// Format dates to ISO string without milliseconds
			var startDate = oneYearAgo.toISOString().split('.')[0] + 'Z';
			var endDate = today.toISOString().split('.')[0] + 'Z';
			const queryId = `${contact.jid}-${new Date().getTime()}`;
			contact.unreadCount = 0;
			if (!contact)
				Promise.resolve(true);

			contact.unreadCount = 0;
			const handleMessage = async (message) => {
				try {
					const msgQueryId = $(message).find('result[xmlns="urn:xmpp:mam:2"]').attr('queryid');
					if (msgQueryId !== queryId)
						return false;
					const msg = $(message).find("forwarded message");
					const from = Strophe.getBareJidFromJid(msg.attr("from"));
					const unseen = $(msg).find("unseen").length;
					const delayIndicator = $(message).find("forwarded delay");
					const dt = delayIndicator.length ? new Date(delayIndicator.attr("stamp")) : new Date();
					const isMe = from === userDataService.user.emailAddress;

					if (!isMe && unseen)
						contact.unreadCount++;
					if (!contact.dt || dt > contact.dt) {
						contact.dt = dt;
					}

				} catch (err) {
					$log.error(err);
				}
				return true;
			};
			const handleRef = stropheConnectionService.parameters.connection.addHandler(handleMessage, null, 'message', "group");

			const handleComplete = async (response) => {
				stropheConnectionService.parameters.connection.deleteHandler(handleRef);
				return true;

			};
			return new Promise((resolve, reject) => {
				stropheConnectionService.parameters.connection.mam.query(vm.username, {
					"with": contact.jid,
					"start": startDate,
					"end": endDate,
					"unseen": true,
					"queryid": queryId,
					onMessage: handleMessage,
					onComplete: (response) => resolve(handleComplete(response)),
					onError: (error) => {
						reject(error);
						stropheConnectionService.parameters.connection.deleteHandler(handleRef);
					}
				});
			});
		}
		async function queryUnseenMessagesAsync(months) {
			months = months || 2;
			var today = new Date();
			var oneYearAgo = new Date();
			oneYearAgo.setMonth(today.getMonth() - months);

			// Format dates to ISO string without milliseconds
			var startDate = oneYearAgo.toISOString().split('.')[0] + 'Z';
			var endDate = today.toISOString().split('.')[0] + 'Z';

			const queryId = `dms-${new Date().getTime()}`;

			const handleMessage = async (message) => {
				try {

					const msgQueryId = $(message).find('result[xmlns="urn:xmpp:mam:2"]').attr('queryid');
					if (msgQueryId !== queryId)
						return false;
					const delayIndicator = $(message).find("forwarded delay");
					const dt = delayIndicator.length ? new Date(delayIndicator.attr("stamp")) : new Date();
					const msg = $(message).find("forwarded message");
					const from = Strophe.getBareJidFromJid(msg.attr("from"));
					const to = Strophe.getBareJidFromJid(msg.attr("to"));
					const unseen = $(message).find("unseen").length;

					const isMe = from === userDataService.user.emailAddress;
					if (isMe) return true;
					const contactFrom = vm.findContact(to);
					if (!contactFrom)
						return true;
					if (unseen)
						contactFrom.unreadCount++;
					if (!contactFrom.dt || dt > contactFrom.dt) {
						contactFrom.dt = dt;
					}

				} catch (err) {
					$log.error(err);
				}
				return true;
			};
			const handleRef = stropheConnectionService.parameters.connection.addHandler(handleMessage, null, 'message', "chat");
			const handleComplete = async (response) => {
				recalculateUnread();
				stropheConnectionService.parameters.connection.deleteHandler(handleRef);
				return true;
			};

			return new Promise((resolve, reject) => {
				stropheConnectionService.parameters.connection.mam.query(vm.username,
					{
						"start": startDate,
						"end": endDate,
						"queryid": queryId,
						"unseen": true,
						"max": 500,
						onMessage: handleMessage,
						onComplete: (response) => resolve(handleComplete(response)),
						onError: (error) => {
							reject(error);
							stropheConnectionService.parameters.connection.deleteHandler(handleRef);
						}
					}

				);

			});
		}


		// Private Functions
		function recalculateUnread(readContactJid) {
			const totalUnreadCount = vm.contactCategories.reduce((total, category) => {
				return category.contacts.reduce((categoryTotal, contact) => {
					if (readContactJid && contact.jid === readContactJid) {
						contact.unreadCount = 0;
					}
					return categoryTotal + (contact.unreadCount || 0);
				}, total);
			}, 0);

			vm.unread = totalUnreadCount;
			$rootScope.$broadcast('xmpp.property-changed', { unreadCount: vm.unread });
		}

		function storeUnreadCount(contact) {
			var counts = vm.parameters.unreadCounts;

			if (!counts[contact.jid]) counts[contact.jid] = {};
			counts[contact.jid].unread = contact.unreadCount;
			counts[contact.jid].time = moment();

			vm.parameters.unreadCounts = counts;
		}

		function loadUnreadCounts() {

			if (!vm.contactCategories || !vm.contactCategories[0]) {
				vm.contactCategories = [{ contacts: [] }];
			}

			for (var key in counts) {
				var foundItem = findContact(key);
				if (foundItem) foundItem.unreadCount = counts[key].unread ? counts[key].unread : 0;
				else vm.contactCategories[0].contacts.push({ jid: key, unreadCount: counts[key].unread });
			}
		}
		function addContact(jid, displayName) {
			var iq = $iq({ type: "set" })
				.c("query", { xmlns: Strophe.NS.ROSTER })
				.c("item", { jid: jid, name: displayName });
			stropheConnectionService.parameters.connection.sendIQ(iq);
			stropheConnectionService.parameters.connection.send($pres({ to: jid, "type": "subscribe" }));
		}

		async function contactFromIqItem(iqItem) {
			var jid = $(iqItem).attr("jid");
			var bareJid = Strophe.getBareJidFromJid(jid);
			var displayJid = jid.split("@")[0] + "@" + coreData.user.domain;
			var name = $(iqItem).attr("name") || bareJid;
			var subscription = $(iqItem).attr("subscription");
			var group = $(iqItem).find("group").text() || "";
			
			var contact = coreDataContacts.getContactByEmail(jid);
			var pic = null;
			if (contact && contact.image && !contact.image.indexOf('data=default') > -1) { pic = contact.image }
			return {
				jid: jid,
				bareJid: bareJid,
				displayJid: displayJid,
				name: name,
				username: !contact ? name : contact.userName,
				subscription: subscription,
				group: group,
				pic: pic
			};
		}

		function findContact(jid) {
			if (!jid) return null;
			jid = jid.toLowerCase();

			return vm.contactCategories
				.flatMap(category => category.contacts || [])
				.find(contact => contact && contact.jid === jid) || null;
		}


		function addRosterContact(contact) {
			if (!contact || !vm.contactCategories) return;

			let foundList = vm.contactCategories.find(list => list.name === contact.group);

			if (!foundList) {
				foundList = { name: contact.group, open: true, contacts: [] };
				vm.contactCategories.push(foundList);
			}

			let existingContact = foundList.contacts.find(c => c.jid === contact.bareJid);

			if (existingContact) {
				let { composeContents } = existingContact;
				Object.assign(existingContact, {
					name: contact.name,
					jid: contact.bareJid,
					displayJid: contact.displayJid,
					status: existingContact.status,
					unreadCount: 0,
					pic: contact.pic,
					username: contact.username,
					composeContents
				});
			} else {
				foundList.contacts.push({
					name: contact.name,
					jid: contact.bareJid,
					displayJid: contact.displayJid,
					status: contact.group.endsWith("Aliases") ? "room" : "offline",
					unreadCount: 0,
					pic: contact.pic,
					username: contact.username
				});
			}
		}


		function removeRosterContact(contact) {
			if (!contact || !vm.contactCategories) return;

			vm.contactCategories.forEach(category => {
				category.contacts = category.contacts.filter(c => c.jid !== contact.bareJid);
			});
		}
	}

})();